import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MarketoForm from "../components/MarketoForm"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../components/VindiciaComponents"
import ContentType from "../components/CustomTemplate/CustomContentType"

const CampaignLP = ({ data }) => {
  const pageData = data.prismicCampaignLandingPage.data

  const icons = pageData.icons

  return (
    <Layout>
      <SEO
        title={pageData.seo_title}
        description={pageData.seo_description}
        keywords={pageData.seo_keywords}
        hideFromIndexing={pageData.hide_from_indexing}
      />
      <div className="default-theme">
        <section
          className="campaign-lp uk-margin-large-top"
          style={{ paddingTop: "110px", paddingBottom: "100px" }}
        >
          <div className="uk-container" style={{ gap: "30px" }}>
            <div className="uk-grid uk-grid-large uk-child-width-1-2@m" data-uk-grid>
              <div data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
                <div
                  style={{ fontWeight: "bold" }}
                  dangerouslySetInnerHTML={{ __html: pageData.title.html }}
                />
                <div dangerouslySetInnerHTML={{ __html: pageData.first_description.html }} />
                {!!icons.length ? (
                  <div
                    className="uk-flex uk-flex-middle uk-flex-between@m uk-flex-column uk-flex-row@m"
                    style={{ gap: "10px" }}
                  >
                    {icons.map(icon => (
                      <div className="uk-width-1-4@s">
                        <Image
                          src={icon.icon.fluid.src}
                          srcSet={icon.icon.fluid.srcSet}
                          alt={icon.icon.alt}
                        />
                      </div>
                    ))}
                  </div>
                ) : null}
                <hr />
                <div dangerouslySetInnerHTML={{ __html: pageData.second_description.html }} />
              </div>
              <div>
                {pageData.marketo_form_id ? (
                  <div
                    className="uk-box-shadow-medium uk-padding-small uk-border-rounded"
                    style={{ background: "#b2a1ff" }}
                  >
                    <h2 className="uk-margin-large-left">Book a demo</h2>
                    <MarketoForm
                      formId={pageData.marketo_form_id}
                      domainAddr="//app-aba.marketo.com"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
        {pageData.body.map(section => {
          return <ContentType key={`section-${section.primary.section_id}`} section={section} />
        })}
      </div>
    </Layout>
  )
}
export default withPreview(CampaignLP)

export const campaignLPQuery = graphql`
  {
    prismicCampaignLandingPage {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        title {
          html
        }
        first_description {
          html
        }
        second_description {
          html
        }
        marketo_form_id

        icons {
          icon {
            fluid(maxWidth: 400) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
        }
        body {
          ... on PrismicCampaignLandingPageBody3ColumnIconCards {
            slice_type
            primary {
              section_theme
              section_background_color
              cards_background_color
              section_heading {
                html
              }
              section_description {
                html
              }
            }
            items {
              title
              description {
                html
              }
              icon {
                alt
                fluid(maxWidth: 50) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              link_url {
                target
                url
              }
              link_title
            }
          }
          ... on PrismicCampaignLandingPageBody2Column {
            primary {
              section_id
              section_name
              section_theme
              section_background_color
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              cta_url {
                target
                url
              }
              cta_text
            }
            slice_label
            slice_type
          }
          ... on PrismicCampaignLandingPageBodyCtaBlock {
            primary {
              section_id
              section_theme
              title {
                html
              }
              description {
                html
              }
              cta_button_link {
                target
                url
              }
              cta_button_text
              section_name
              section_background
              section_vimeo_id
              section_vimeo_height
              section_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicCampaignLandingPageBody4ColumnIcon {
            slice_type
            primary {
              section_theme
              section_background_color
              section_heading {
                html
              }
              section_description {
                html
              }
            }
            items {
              title
              description {
                html
              }
              icon {
                alt
                fluid(maxWidth: 50) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicCampaignLandingPageBodyLogoCarousel {
            primary {
              section_id
              section_name
              title {
                html
              }
              description {
                html
              }
            }
            slice_type
            items {
              logo {
                fixed(width: 150) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicCampaignLandingPageBody3ColumnCards {
            primary {
              section_id
              section_name
              section_title_centered {
                text
              }
              background_color
              card_background_color
            }
            items {
              card_body_content {
                html
              }
              card_header_content {
                html
              }
              card_header_image {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicCampaignLandingPageBody2ColumnVideo {
            id
            slice_label
            slice_type
            primary {
              copy {
                html
              }
              section_id
              section_name
              section_theme
              section_background_color
              vimeo_id
            }
          }
          ... on PrismicCampaignLandingPageBodyFaq {
            id
            primary {
              section_id
              section_name
              button_link {
                target
                url
              }
              button_title
              description {
                html
              }
              heading {
                html
              }
            }
            items {
              answer
              question
            }
            slice_label
            slice_type
          }
          ... on PrismicCampaignLandingPageBody1ColumnRepeatable {
            slice_label
            slice_type
            primary {
              section_id
              section_theme
              section_background_color
              section_title {
                html
              }
              section_description {
                html
              }
            }
            items {
              copy_text {
                html
              }
              author {
                html
              }
              job_title {
                html
              }
              cta_text
              cta_url {
                url
                target
              }
            }
          }
        }
      }
    }
  }
`
